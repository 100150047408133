import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Label from 'reactstrap/lib/Label';
import { randomInt } from 'client/utils/seed-randomizers';
import classnames from 'classnames';
import { map, get, flatten } from 'lodash';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { isSelectDisabled } from './utils';

import './select.scss';

export class Select extends Component {
  constructor(props) {
    super(props);
    this.state = { isChangedDefaultOption: false };
  }

  componentDidMount() {
    const { useFloatingLabel, value, defaultValue, onChange } = this.props;
    if (!useFloatingLabel) return;
    if (value || defaultValue) this.moveLabel();
    if (defaultValue) onChange(defaultValue);
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue, defaultValue: prevDefaultValue } = prevProps;
    const { useFloatingLabel, value, defaultValue, onChange, moveFloatingLabelBack } = this.props;
    const { isChangedDefaultOption } = this.state;
    if (!useFloatingLabel) return;
    if ((value || defaultValue) && (prevValue !== value || prevDefaultValue !== defaultValue)) this.moveLabel();
    if (moveFloatingLabelBack && !value && isChangedDefaultOption) this.moveLabelBack();
    if (prevDefaultValue !== defaultValue) onChange(defaultValue);
  }

  onChange = e => {
    const { options, valueKey, defaultValue, isFirstOptionDisabled, onChange, useFloatingLabel } = this.props;
    const target = e && e.target;
    const defaultOptionValue = this.getDefaultOptionValue();
    const selectedOption =
      target &&
      options &&
      options.find(
        option =>
          (option[valueKey] || option[valueKey] === 0) && option[valueKey].toString() === target.value.toString()
      );
    if (selectedOption) {
      if (useFloatingLabel) this.moveLabel();
      onChange(selectedOption, e);
    } else if (defaultOptionValue && !isFirstOptionDisabled) {
      onChange(defaultValue, e);
    }
  };

  onChangeByCategory = e => {
    const { optionsGrouped, valueKey } = this.props;
    const { selectedIndex, options } = e.target;
    const selectedOptionValue = get(options, `[${selectedIndex}].value`, '');
    const selectedOption = flatten(Object.values(optionsGrouped)).find(
      option => option[valueKey].toString() === selectedOptionValue
    );

    this.props.onChange(selectedOption);
  };

  getOptionList = (options, { optionClassName, valueKey, labelKey }) =>
    options.map((item, idx) => (
      <option
        key={idx} // eslint-disable-line react/no-array-index-key
        className={optionClassName}
        value={item[valueKey]}
        disabled={item.disabled}
      >
        {item[labelKey]}
      </option>
    ));

  getOptionByCategory = options => {
    const { optionClassName, valueKey, labelKey } = this.props;

    return map(options, (option, category) =>
      option.length ? (
        <optgroup label={category} className={optionClassName} key={category}>
          {this.getOptionList(option, { optionClassName, valueKey, labelKey })}
        </optgroup>
      ) : null
    );
  };

  getPreSavedOption = () => {
    const { optionClassName, preSavedValue } = this.props;

    return (
      <option className={optionClassName} value={preSavedValue} disabled={false}>
        {preSavedValue}
      </option>
    );
  };

  // Used for validation
  // eslint-disable-next-line no-restricted-syntax
  get value() {
    return this.props.value || this.props.defaultValue;
  }

  getDefaultOptionValue() {
    const { useFloatingLabel, defaultValue, toggle } = this.props;
    return useFloatingLabel && !defaultValue ? ' ' : toggle;
  }

  moveLabel() {
    this.setState({ isChangedDefaultOption: true });
  }

  moveLabelBack() {
    this.setState({ isChangedDefaultOption: false });
  }

  render() {
    const {
      name,
      toggle,
      isFirstOptionDisabled,
      options,
      optionsGrouped,
      valueKey,
      labelKey,
      value,
      preSavedValue,
      defaultValue,
      disabled,
      labelText,
      isLabelHidden,
      labelClassName,
      className,
      toggleClassName,
      optionClassName,
      inputClassName,
      hasOptionTruncate,
      customArrowClasses,
      id,
      ariaDescribedBy,
      ariaInvalid,
      enableSpinner,
      useFloatingLabel,
      moveFloatingLabelBack,
      labelAddon,
      ...args
    } = this.props;

    const { isChangedDefaultOption } = this.state;

    const defaultOptionValue = this.getDefaultOptionValue();
    const selectId = id || `sel-${randomInt()}`;
    const hasOptions = !!(options && options.length);
    const hasOptionsGrouped = !!(optionsGrouped && Object.keys(optionsGrouped).length);
    const showSpinner = enableSpinner && !hasOptions;
    let Wrapper = Fragment;
    let wrapperProps = {};
    if (showSpinner) {
      Wrapper = 'div';
      wrapperProps = {
        className: 'pos-r',
      };
    }

    return (
      <Fragment>
        {labelText && !isLabelHidden && !useFloatingLabel && (
          <Fragment>
            <Label for={selectId} className={labelClassName}>
              {labelText}
            </Label>
            {labelAddon}
          </Fragment>
        )}
        <Wrapper {...wrapperProps}>
          {showSpinner && (
            <div className="select-spinner d-flex align-items-center">
              <span className="input-content">{defaultOptionValue}</span>
              <Spinner color="primary-darker" className="d-inline-block ml-0_5" size={14} />
            </div>
          )}
          <select
            {...args}
            id={selectId}
            name={name}
            {...(labelText && isLabelHidden ? { 'aria-label': labelText } : {})}
            className={classnames(inputClassName || className, 'text-start')}
            disabled={isSelectDisabled({ optionsGrouped, options, preSavedValue, disabled })}
            value={value || preSavedValue || defaultValue}
            onChange={hasOptionsGrouped ? this.onChangeByCategory : this.onChange}
            onFocus={this.props.onFocus}
            {...(ariaInvalid && ariaDescribedBy ? { 'aria-describedby': ariaDescribedBy, 'aria-invalid': true } : {})}
          >
            {defaultOptionValue && (
              <option
                className={toggleClassName}
                disabled={isFirstOptionDisabled}
                value={defaultValue}
                hidden={useFloatingLabel}
              >
                {defaultOptionValue}
              </option>
            )}
            {// If optionsGrouped exists then render options divided by optgroups as category
            (!(hasOptionsGrouped || hasOptions) && preSavedValue && this.getPreSavedOption()) ||
              (hasOptionsGrouped && this.getOptionByCategory(optionsGrouped)) ||
              (hasOptions && this.getOptionList(options, { valueKey, labelKey, optionClassName }))}
            {/* This hack fixes issue with truncated long text on iOS */}
            {!hasOptionTruncate && <optgroup label="" />}
          </select>
        </Wrapper>
        {useFloatingLabel && labelText && !isLabelHidden && (
          <Label
            for={selectId}
            className={classnames(labelClassName, {
              'select-label form-control-label size-16': useFloatingLabel,
              'select-label-up': isChangedDefaultOption,
            })}
          >
            {labelText}
          </Label>
        )}
      </Fragment>
    );
  }
}

export const SelectPropTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  toggle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFirstOptionDisabled: PropTypes.bool,
  options: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  optionsGrouped: PropTypes.shape({}),
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  preSavedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLabelHidden: PropTypes.bool,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  toggleClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  hasOptionTruncate: PropTypes.bool,
  'data-tracking-id': PropTypes.string, // todo: remove when PLAT-174 is fixed
  'data-tracking-value': PropTypes.string, // todo: remove when PLAT-174 is fixed
  'data-no-refresh': PropTypes.bool,
  'data-testid': PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  ariaInvalid: PropTypes.bool,
  enableSpinner: PropTypes.bool,
  useFloatingLabel: PropTypes.bool, // used only with StyledSelect component
  moveFloatingLabelBack: PropTypes.bool,
  labelAddon: PropTypes.node,
  'aria-required': PropTypes.bool,
  required: PropTypes.bool,
};

export const SelectDefaultProps = {
  id: null,
  toggle: null,
  isFirstOptionDisabled: true,
  options: [],
  optionsGrouped: {},
  valueKey: 'value',
  labelKey: 'label',
  value: '',
  preSavedValue: '',
  defaultValue: '',
  disabled: false,
  onFocus: () => {},
  isLabelHidden: false,
  labelText: '',
  labelClassName: '',
  className: '',
  toggleClassName: '',
  optionClassName: '',
  inputClassName: '',
  hasOptionTruncate: true,
  'data-tracking-id': null, // todo: remove when PLAT-174 is fixed
  'data-tracking-value': null, // todo: remove when PLAT-174 is fixed
  'data-no-refresh': false,
  'data-testid': null,
  ariaDescribedBy: '',
  ariaInvalid: false,
  enableSpinner: false,
  useFloatingLabel: false,
  moveFloatingLabelBack: false,
  labelAddon: null,
  'aria-required': undefined,
  required: undefined,
};

Select.propTypes = { ...SelectPropTypes };

Select.defaultProps = { ...SelectDefaultProps };
