/*
 *
 * Replaces the last occurance of whitespace with HTML entitiy &nbsp;so that
 * there would be no orphans when text wraps
 *
 * Example:
 * {getTitleWithNoOrphans('Shoppers go to Edmunds first')}
 *
 * If using HTML tags, wrap with <ContentFragment/>
 * <ContentFragment>
 *    {getTitleWithNoOrphans(`Let’s find your <span class="fst-italic perfect text-turquoise-80">perfect</span> car`)}
 * </ContentFragment>
 *
 */
export function getTitleWithNoOrphans(title) {
  return title.trim().replace(/\s(?=\S*$)/, '\u00a0');
}
