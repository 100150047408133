import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import classnames from 'classnames';
import { getFullImageUrl } from 'client/utils/image-helpers';
import { IMAGE_SIZES } from 'site-modules/shared/constants/editorial/editorial';
import { SectionHeading } from 'site-modules/shared/components/editorial/section-heading/section-heading';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { Link } from 'site-modules/shared/components/link/link';
import { getTitleWithNoOrphans } from 'site-modules/shared/utils/get-title-with-no-orphans';
import './category-cards.scss';

function CategoryCard({
  content,
  isLabelHidden,
  hideMobilePromoText,
  enableEdmundsTitle,
  enableClickTracking,
  noFollowLinks,
  contentClass,
  index,
}) {
  const image = content.metadata('item-image').value();
  const promoText = content.metadata('item-text').value();
  const title = content.metadata('item-title').value();
  const url = content.metadata('item-link').value();
  const promoLabel = content.metadata('promoLabel').value();
  const sponsoredTitle = content.metadata('sponsored-title').value();

  const imagePath = getFullImageUrl(image, IMAGE_SIZES.sm_md);
  return (
    <div className="category-card pt-0_5 mb-1 pos-r">
      {!isLabelHidden && (
        <div className={classnames('promo-label mb-0_5', { 'hidden-sm-down': hideMobilePromoText })}>
          {/* todo - review PLAT-3859 disabling */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {promoLabel && <a className="h7 text-primary fw-bold text-uppercase">{promoLabel}</a>}
        </div>
      )}
      <AspectRatioContainer aspectRatio="16:9" tag="figure">
        <img src={imagePath} alt="" className="category-card-image rounded w-100" loading="lazy" decoding="async" />
      </AspectRatioContainer>
      {sponsoredTitle && (
        <div className="mt-0_75 text-uppercase text-primary-darker size-10 fw-bold">Sponsored Content</div>
      )}
      {enableEdmundsTitle && !sponsoredTitle && <div className="mt-0_75 size-10">Edmunds</div>}
      <div className="mt-0_75">
        <Link
          to={url}
          data-tracking-id={enableClickTracking ? 'home_content_feature_article_click' : null}
          data-tracking-value={sponsoredTitle ? `SPONSORED:${title}` : `UNSPONSORED:${title}`}
          rel={noFollowLinks ? 'nofollow' : null}
          className="category-card-link fw-bold text-gray-darker"
          aria-describedby={`describedby-${index}`}
        >
          {title}
        </Link>
        <div
          className={classnames('mt-0_25', contentClass, { 'hidden-sm-down': hideMobilePromoText })}
          id={`describedby-${index}`}
        >
          {promoText}
        </div>
      </div>
    </div>
  );
}

CategoryCard.propTypes = {
  content: PropTypes.shape({}).isRequired,
  isLabelHidden: PropTypes.bool,
  enableClickTracking: PropTypes.bool,
  enableEdmundsTitle: PropTypes.bool,
  hideMobilePromoText: PropTypes.bool,
  noFollowLinks: PropTypes.bool,
  contentClass: PropTypes.string,
  index: PropTypes.number,
};

CategoryCard.defaultProps = {
  isLabelHidden: false,
  enableClickTracking: false,
  enableEdmundsTitle: false,
  hideMobilePromoText: true,
  noFollowLinks: false,
  contentClass: '',
  index: 0,
};

export function CategoryCards({
  entry,
  enableEdmundsTitle,
  enableClickTracking,
  hideMobilePromoText,
  sectionHeadingClassName,
  noFollowLinks,
  className,
  headerTag,
}) {
  const { title, linkout } = (() => {
    const data = entry.getAllMetadata();
    return {
      title: data.title,
      linkout: {
        href: data['view-more-url'],
        text: data['view-more-title'],
      },
    };
  })();

  return (
    <Fragment>
      {title && (
        <SectionHeading linkout={linkout} linkoutText={linkout.text} {...sectionHeadingClassName} tag={headerTag}>
          {getTitleWithNoOrphans(title)}
        </SectionHeading>
      )}
      <Row className={classnames('category-cards', className)}>
        {entry.children().map((category, index) => (
          <Col xs={12} md={6} lg={4} key={category.metadata('item-title').value()}>
            <CategoryCard
              content={category}
              enableClickTracking={enableClickTracking}
              hideMobilePromoText={hideMobilePromoText}
              enableEdmundsTitle={enableEdmundsTitle}
              noFollowLinks={noFollowLinks}
              index={index}
            />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
}

CategoryCards.propTypes = {
  entry: PropTypes.shape({}).isRequired,
  enableEdmundsTitle: PropTypes.bool,
  enableClickTracking: PropTypes.bool,
  hideMobilePromoText: PropTypes.bool,
  noFollowLinks: PropTypes.bool,
  sectionHeadingClassName: PropTypes.shape({
    textClassName: PropTypes.string,
    containerClassName: PropTypes.string,
  }),
  className: PropTypes.string,
  headerTag: PropTypes.string,
};

CategoryCards.defaultProps = {
  enableEdmundsTitle: false,
  enableClickTracking: false,
  noFollowLinks: false,
  hideMobilePromoText: true,
  sectionHeadingClassName: null,
  className: null,
  headerTag: 'header',
};
